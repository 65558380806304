<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'
import isNumber from '@/utils/isNumber'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { getters } = store

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const props = defineProps({
    filtersSelected: {
        required: false
    },
    defaultFilters: {
        required: false
    }
})

const state = reactive({
    searchInputLocation: '',
    isClear: false
})

const filters = computed(() => {
    return getters['facilitySession/filters']
})

const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

const validateMinValue = (value, compare) => {
    if (!compare || !value) {
        return true
    }

    return (Number(value) || '') <= Number(compare) || t('error.MustBeLowerThenMaxValue')
}

const validateMaxValue = (value, compare) => {
    if (!compare || !value) {
        return true
    }

    return (Number(value) || '') >= Number(compare) || t('error.MustBeGraterThenMinValue')
}

const removeElement = (field, index) => {
    typeof props.filtersSelected[field] === 'string'
        ? (props.filtersSelected[field] = [])
        : props.filtersSelected[field].splice(index, 1)
    setFilter(field, props.filtersSelected[field])
}

const debounce = (func, delay) => {
    let timerId
    return function (...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function (field, value) {
    emit('setFilter', { field, value })
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
    state.isClear = true
}

const dateFromTo = ({ dateFrom: from, dateTo: to, isClear }) => {
    setFilter('date', {
        from: from || null,
        to: to || null
    })
    state.isClear = isClear
}
</script>

<template>
    <v-container fluid class="facility-sessions-filter__filters pa-3 pa-md-8">
        <v-row class="pt-2 ma-0">
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_minutes.min"
                    :rules="[validateMinValue(filtersSelected.total_minutes.min, filtersSelected.total_minutes.max)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.DurationMin')"
                    @update:model-value="
                        setFilter('total_minutes', { min: $event, max: filtersSelected.total_minutes.max })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_minutes.max"
                    :rules="[validateMaxValue(filtersSelected.total_minutes.max, filtersSelected.total_minutes.min)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.DurationMax')"
                    @update:model-value="
                        setFilter('total_minutes', { max: $event, min: filtersSelected.total_minutes.min })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.average_power.min"
                    :rules="[validateMinValue(filtersSelected.average_power.min, filtersSelected.average_power.max)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.averagePowerMin')"
                    @update:model-value="
                        setFilter('average_power', { min: $event, max: filtersSelected.average_power.max })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.average_power.max"
                    :rules="[validateMaxValue(filtersSelected.average_power.max, filtersSelected.average_power.min)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.averagePowerMax')"
                    @update:model-value="
                        setFilter('average_power', { max: $event, min: filtersSelected.average_power.min })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.ac_dc_categories"
                    :items="filters.connector_category"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    clearable
                    chips
                    multiple
                    :placeholder="t('sessions.AC/DC')"
                    @update:model-value="setFilter('ac_dc_categories', $event)"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" outlined>
                            <span class="element">
                                {{ item.name }}
                                <Btn class="element-remove" @click="removeElement('ac_dc_categories', index)">
                                    <v-icon class="clear-icon pb-1"> mdi-window-close </v-icon>
                                </Btn>
                            </span>
                        </v-chip>
                    </template>
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.status_session"
                    :items="filters.status_session"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    clearable
                    chips
                    :placeholder="t('sessions.RunningSession')"
                    @update:model-value="setFilter('status_session', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="ma-0 px-0">
                <DatePicker
                    class="ma-0"
                    :label1="t('invoices.DateFrom')"
                    :label2="t('invoices.DateTo')"
                    :is-clear="state.isClear"
                    @date="dateFromTo"
                />
            </v-col>
        </v-row>
        <v-row class="pb-4 ma-0">
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.charger_id"
                    :items="filters.devices"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    multiple
                    clearable
                    chips
                    :placeholder="t('sessions.Device')"
                    @update:model-value="setFilter('charger_id', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.connector_type_code"
                    :items="filters.connector_type"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    multiple
                    chips
                    clearable
                    :placeholder="t('sessions.connectorType')"
                    @update:model-value="setFilter('connector_type_code', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    :model-value="filtersSelected.location_id"
                    :items="filters.locations"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    clearable
                    multiple
                    chips
                    :label="t('sessions.Location')"
                    @update:model-value="setFilter('location_id', $event)"
                >
                </v-autocomplete>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    v-model="filtersSelected.category"
                    :items="filters.charger_type ? filters.charger_type : []"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    clearable
                    chips
                    :label="t('sessions.chargerType')"
                    @update:model-value="setFilter('category', $event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_kwh.min"
                    :rules="[validateMinValue(filtersSelected.total_kwh.min, filtersSelected.total_kwh.max)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.totalkWhMin')"
                    @update:model-value="setFilter('total_kwh', { min: $event, max: filtersSelected.total_kwh.max })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_kwh.max"
                    :rules="[validateMaxValue(filtersSelected.total_kwh.max, filtersSelected.total_kwh.min)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.totalkWhMax')"
                    @update:model-value="setFilter('total_kwh', { max: $event, min: filtersSelected.total_kwh.min })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.max_power.min"
                    :rules="[validateMinValue(filtersSelected.max_power.min, filtersSelected.max_power.max)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.MaxkWhMin')"
                    @update:model-value="setFilter('max_power', { min: $event, max: filtersSelected.max_power.max })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.max_power.max"
                    :rules="[validateMaxValue(filtersSelected.max_power.max, filtersSelected.max_power.min)]"
                    density="compact"
                    variant="outlined"
                    type="number"
                    clearable
                    :label="t('sessions.MaxkWhMax')"
                    @update:model-value="setFilter('max_power', { max: $event, min: filtersSelected.max_power.min })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col v-if="store.state.account.drivers_count > 1" class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    v-value="filtersSelected.drivers"
                    :items="filters.drivers ? filters.drivers : []"
                    density="compact"
                    item-title="name"
                    chips
                    item-value="key"
                    variant="outlined"
                    multiple
                    clearable
                    :label="t('sessions.Drivers')"
                    @update:model-value="setFilter('driver_id', $event)"
                />
            </v-col>
            <v-col v-if="showClear" class="py-0" md="3" sm="6" cols="12">
                <Btn text color="primary" @click="clearFilters()">
                    <v-icon class="clear-icon pr-1"> mdi-delete </v-icon>{{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
