<script setup>
import { ref, computed, watch, onBeforeMount } from 'vue'
import store from '@/store'

// components
import Logo from '@/components/icons/Logo.vue'

// utils
import { ROLES } from '@/constants/roles'

// translations
import { useI18n } from 'vue-i18n'

// configs
import { Roles } from '@/configs/index.js'

const { t } = useI18n()

const { state, commit } = store

const emit = defineEmits(['closeMenu'])
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false
    }
})

const isMenuOpen = ref(false)

const sidebarExpanded = computed({
    get() {
        return state.sidebarExpanded
    },
    set(value) {
        commit('setFieldValue', {
            field: 'sidebarExpanded',
            value
        })
    }
})
const sidebarLinks = computed(() => {
    const links = [
        {
            title: t('sidebar.Dashboard'),
            id: 'dashboard',
            link: '/dashboard',
            icon: 'mdi-view-dashboard',
            scope: 'dashboard',
            visible: checkAccess('dashboard', 'read')
        },
        {
            title: t('sidebar.Fleet'),
            id: 'fleet',
            link: '/fleet-dashboard',
            icon: 'mdi-view-dashboard-variant',
            scope: 'fleetDashboard',
            visible: checkAccess('fleetDashboard', 'read')
        },
        {
            title: t('sidebar.News'),
            id: 'news',
            link: '/news',
            icon: 'mdi-newspaper-variant-multiple',
            scope: 'news',
            visible: checkAccess('news', 'read')
        },
        {
            title: t('sidebar.locationMap'),
            id: 'location-map',
            link: '/locationMap',
            icon: 'mdi-map-marker',
            scope: 'locationMap',
            visible: checkAccess('account', 'read')
        },
        {
            title: t('sidebar.Sessions'),
            id: 'sessions',
            link: '/sessions',
            icon: 'mdi-lightning-bolt',
            scope: 'sessions',
            visible: checkAccess('sessions', 'read')
        },
        {
            title: t('sidebar.Invoices'),
            id: 'invoices',
            link: '/invoices',
            icon: 'mdi-currency-eur',
            scope: 'invoices',
            visible: checkAccess('invoices', 'read')
        },
        {
            title: t('sidebar.Coupons'),
            id: 'coupons',
            link: '/coupons',
            icon: 'mdi-ticket',
            scope: 'coupons',
            visible: checkAccess('coupons', 'read')
        },
        {
            title: t('sidebar.chargingCards'),
            id: 'chargingCards',
            link: '/charging-cards',
            icon: 'mdi-credit-card-multiple',
            scope: 'chargingCards',
            visible: checkAccess('chargingCards', 'read')
        },
        {
            title: t('sidebar.Settings'),
            id: 'settings',
            link: settingsLink(),
            icon: 'mdi-account-settings',
            scope: 'account',
            visible: checkAccess('account', 'read')
        }
        // {title: t('sidebar.Help'), link: '/help', icon: 'mdi-help', scope: 'help', visible: false}, TODO: removed temporary
    ]

	// Condition changed so it will be always visible
    if (typeof state.account.drivers_count === 'undefined' || state.account.drivers_count > 0) {
        links.splice(6, 0, {
            title: t('sidebar.Drivers'),
            id: 'drivers',
            link: '/drivers',
            icon: 'mdi-account-box',
            scope: 'driverManagement',
            visible: checkAccess('driverManagement', 'read')
        })
    }

    if ((state.account.roles.includes(Roles.driver) && !state.account.roles.includes(Roles.payer))) {
        if (state.account.homeChargers && state.account.homeChargers.length > 0) {
            links.splice(6, 0, {
                title: t('sidebar.Chargers'),
                id: 'chargers',
                link: '/charger-management',
                icon: 'mdi-ev-station',
                scope: 'chargerManagement',
                visible: checkAccess('chargerManagement', 'read')
            })
        }
    } else if (state.account.roles.includes(Roles.facilityManager) || state.account.roles.includes(Roles.fleetManager)) {
        links.splice(6, 0, {
            title: t('sidebar.Chargers'),
            id: 'chargers',
            link: '/charger-management',
            icon: 'mdi-ev-station',
            scope: 'chargerManagement',
            visible: checkAccess('chargerManagement', 'read')
        })
    }

    commit('settings/setFieldValue', {
        field: 'links',
        value: links
    })
    return links
})

watch(() => props.isActive, (val) => {
    if (val) {
        const scrollY = window.scrollY
        document.body.style.top = `-${scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflow = 'hidden'
        document.getElementsByClassName('modal-backdrop')[0].classList.add('show')
    } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.overflow = ''
        document.getElementsByClassName('modal-backdrop')[0].classList.remove('show')
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
})

const checkAccess = (scope, action) => {
    try {
        return !!state.account.roles.some(role => ROLES[role][scope] && ROLES[role][scope][action])
    } catch (e) {
        return false
    }
}
const settingsLink = () => {
    if (state.account.roles) {
        const roles = [...state.account.roles]
        if (roles) {
            return roles.length === 1 && roles.includes('driver') ? `/drivers/${store.state.account.driver.id}` : '/account'
        }
    }
    return '/account'
}
const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
    setLocalStorage()
    if (!isMenuOpen.value) {
        emit('closeMenu')
        sidebarExpanded.value = false
        return
    }
    sidebarExpanded.value = true
}
const handleInitialSidebarState = () => {
    if (window.innerWidth > 960) {
        if (localStorage.getItem('GW_CZ_SidebarMenuExpanded')) {
            sidebarExpanded.value = getLocalStorage()
            isMenuOpen.value = getLocalStorage()
            return
        }
        setLocalStorage()
        isMenuOpen.value = true
        sidebarExpanded.value = true
        return
    }
    isMenuOpen.value = false
}
const setLocalStorage = () => {
    localStorage.setItem('GW_CZ_SidebarMenuExpanded', isMenuOpen.value)
}
const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem('GW_CZ_SidebarMenuExpanded'))
}

onBeforeMount(() => {
    handleInitialSidebarState()
})
</script>

<template>
    <div
        :class="{ 'active-mobile': props.isActive, 'active': isMenuOpen }"
        class="menu"
    >
        <header class="menu__header">
            <v-img
                v-if="!isMenuOpen"
                class="menu__logo"
                src="assets/icons/menuLogo.svg"
            />
            <div
                v-else
                class="menu__expanded-logo"
            >
                <Logo
                    height="24px"
                    secondary
                    width="124px"
                />
            </div>
            <v-tooltip right>
                <template v-slot:activator="{ props }">
                    <v-btn
                        :icon="`mdi-chevron-${(isMenuOpen) ? 'left' : 'right'}`"
                        class="toggle-menu d-none d-md-flex align-center"
                        color="white"
                        rounded="xl"
                        size="x-small"
                        v-bind="props"
                        @click="toggleMenu()"
                    />
                </template>
                <span>{{ (isMenuOpen) ? t('tooltip.closeMenu') : t('tooltip.openMenu') }}</span>
            </v-tooltip>
        </header>
        <main
            :class="{ 'menu__list__scrollable': props.isActive }"
            class="menu__main"
        >
            <ul class="menu__list pa-0">
                <router-link
                    v-for="{ id, link, icon, title, visible } in sidebarLinks"
                    v-if="sidebarLinks.length"
                    :key="link"
                    :class="{
                        'menu__item': true,
                        'menu__item--active': $route.path === link,
                        'menu__item--invisible': !visible
                    }"
                    :data-test="id"
                    :to="link"
                    tag="li"
                    @click.native="emit('closeMenu')"
                >
                    <v-tooltip
                        :disabled="isMenuOpen"
                        right
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props">{{ icon }}</v-icon>
                        </template>
                        <span>{{ title }}</span>
                    </v-tooltip>
                    <span class="menu__title">{{ title }}</span>
                </router-link>
                <li
                    class="menu__item"
                    data-test="logout"
                >
                    <router-link to="/logout">
                        <v-tooltip
                        :disabled="isMenuOpen"
                        right
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props">mdi-logout</v-icon>
                        </template>
                        <span>{{ t('sidebar.Logout') }}</span>
                    </v-tooltip>
                    <span class="menu__title">{{ t('sidebar.Logout') }}</span>
                </router-link>
            </li>
        </ul>
    </main>
</div></template>
