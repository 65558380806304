<script setup>
import { reactive, computed } from 'vue'
import store from '@/store'
const { dispatch } = store

// components
import Btn from '@/components/inputs/Btn.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'

// utils
import { useI18n } from 'vue-i18n'
import isNumber from '@/utils/isNumber'
import isObjEqual from '@/utils/isObjEqual'

const { t } = useI18n()

const props = defineProps(['filtersSelected', 'defaultFilters', 'isDriverDetail'])
const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const debounce = (func, delay) => {
    let timerId
    return function (...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const state = reactive({
    isSearching: false,
    isClear: false,
    searchInputLocation: '',
    timerId: null
})

const filters = computed(() => {
    return store.state.filters.sessionsFilters
})
const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

const clearFilters = () => {
    state.isClear = true
    emit('clearFilters')
}
const validateMinValue = (value, compare) => {
    if (!compare || !value) {
        return true
    }
    return (Number(value) || '') <= Number(compare) || t('error.MustBeLowerThenMaxValue')
}
const validateMaxValue = (value, compare) => {
    if (!compare || !value) {
        return true
    }
    return (Number(value) || '') >= Number(compare) || t('error.MustBeGraterThenMinValue')
}
const removeElement = (field, index) => {
    props.filtersSelected[field].splice(index, 1)
    setFilter(field, props.filtersSelected[field])
}
const setFilter = (field, value) => {
    emit('setFilter', { field, value })
    clearTimeout(state.timerId)
    state.timerId = setTimeout(() => {
        emit('getItems')
    }, 1500)
}
const dateFromTo = ({ dateFrom: from, dateTo: to, isClear }) => {
    setFilter('date', {
        from: from || null,
        to: to || null
    })
    state.isClear = isClear
}

const searchLocation = debounce(async function (value) {
    state.isSearching = true
    await dispatch('filters/getFilterLocation', { value })
    state.isSearching = false
}, 1500)
</script>

<template>
    <v-container fluid class="sessions__filters pa-3 pa-md-8">
        <v-row class="pt-2 ma-0" no-gutters>
            <v-col class="py-0" sm="6" cols="12">
                <DatePicker
                    class="pb-3"
                    :label1="t('invoices.DateFrom')"
                    :label2="t('invoices.DateTo')"
                    :is-clear="state.isClear"
                    :from-date="props.filtersSelected.date.from"
                    :to-date="props.filtersSelected.date.to"
                    @date="dateFromTo"
                />
            </v-col>
        </v-row>
        <v-row class="pt-2 ma-0">
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="props.filtersSelected.price.min"
                    :rules="[validateMinValue(props.filtersSelected.price.min, props.filtersSelected.price.max)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.PriceMin')"
                    @update:model-value="setFilter('price', { min: $event, max: props.filtersSelected.price.max })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="props.filtersSelected.price.max"
                    :rules="[validateMaxValue(props.filtersSelected.price.max, props.filtersSelected.price.min)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.PriceMax')"
                    @update:model-value="setFilter('price', { max: $event, min: props.filtersSelected.price.min })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.connector_max_power.min"
                    :rules="[
                        validateMinValue(
                            filtersSelected.connector_max_power.min,
                            filtersSelected.connector_max_power.max
                        )
                    ]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.minConnectorPower')"
                    @update:model-value="
                        setFilter('connector_max_power', { min: $event, max: filtersSelected.connector_max_power.max })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.connector_max_power.max"
                    :rules="[
                        validateMaxValue(
                            filtersSelected.connector_max_power.max,
                            filtersSelected.connector_max_power.min
                        )
                    ]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.maxConnectorPower')"
                    @update:model-value="
                        setFilter('connector_max_power', { max: $event, min: filtersSelected.connector_max_power.min })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_minutes.min"
                    :rules="[validateMinValue(filtersSelected.total_minutes.min, filtersSelected.total_minutes.max)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.DurationMin')"
                    @update:model-value="
                        setFilter('total_minutes', { min: $event, max: filtersSelected.total_minutes.max })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_minutes.max"
                    :rules="[validateMaxValue(filtersSelected.total_minutes.max, filtersSelected.total_minutes.min)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.DurationMax')"
                    @update:model-value="
                        setFilter('total_minutes', { max: $event, min: filtersSelected.total_minutes.min })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.average_power.min"
                    :rules="[validateMinValue(filtersSelected.average_power.min, filtersSelected.average_power.max)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.averagePowerMin')"
                    @update:model-value="
                        setFilter('average_power', { min: $event, max: filtersSelected.average_power.max })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.average_power.max"
                    :rules="[validateMaxValue(filtersSelected.average_power.max, filtersSelected.average_power.min)]"
                    density="compact"
                    class="hide-arrows"
                    variant="outlined"
                    type="number"
                    clearable
                    :min="0"
                    :label="t('sessions.averagePowerMax')"
                    @update:model-value="
                        setFilter('average_power', { max: $event, min: filtersSelected.average_power.min })
                    "
                    @keypress="isNumber($event)"
                />
            </v-col>
        </v-row>
        <v-row class="ma-0">
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.ac_dc_categories"
                    :items="filters.connector_category || []"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    clearable
                    multiple
                    chips
                    :placeholder="t('sessions.AC/DC')"
                    @update:model-value="setFilter('ac_dc_categories', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.connector_type_code"
                    :items="filters.connector_type || []"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    multiple
                    clearable
                    chips
                    :placeholder="t('sessions.connectorType')"
                    @update:model-value="setFilter('connector_type_code', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.invoice_status"
                    :items="filters.invoice_status || []"
                    item-title="name"
                    item-value="key"
                    density="compact"
                    variant="outlined"
                    multiple
                    clearable
                    chips
                    :placeholder="t('sessions.invoiceStatus')"
                    @update:model-value="setFilter('invoice_status', $event)"
                >
                </v-select>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    :items="filters.location || []"
                    item-title="name"
                    item-value="location_id"
                    density="compact"
                    variant="outlined"
                    clearable
                    multiple
                    chips
                    :label="t('sessions.Location')"
                    @update:search="searchLocation"
                    @update:model-value="setFilter('location_id', $event)"
                    :loading="state.isSearching"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" variant="outlined">
                            <span class="element">
                                {{ item.location_name }}
                                <Btn class="element-remove" @click="removeElement('location_id', index)">
                                    <v-icon class="clear-icon pb-1"> mdi-window-close </v-icon>
                                </Btn>
                            </span>
                        </v-chip>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.invoice_number"
                    density="compact"
                    variant="outlined"
                    clearable
                    :label="t('sessions.InvoiceNumber')"
                    @update:model-value="setFilter('invoice_number', $event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_kwh.min"
                    :rules="[validateMinValue(filtersSelected.total_kwh.min, filtersSelected.total_kwh.max)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.totalkWhMin')"
                    @update:model-value="setFilter('total_kwh', { min: $event, max: filtersSelected.total_kwh.max })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.total_kwh.max"
                    :rules="[validateMaxValue(filtersSelected.total_kwh.max, filtersSelected.total_kwh.min)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.totalkWhMax')"
                    @update:model-value="setFilter('total_kwh', { max: $event, min: filtersSelected.total_kwh.min })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-select
                    :model-value="filtersSelected.is_roaming"
                    :items="filters.roaming || null"
                    density="compact"
                    variant="outlined"
                    item-title="name"
                    item-value="key"
                    clearable
                    :placeholder="t('sessions.Roaming')"
                    @update:model-value="setFilter('is_roaming', $event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    :model-value="filtersSelected.category"
                    :items="filters.charger_type ? filters.charger_type : []"
                    density="compact"
                    item-title="name"
                    item-value="key"
                    variant="outlined"
                    clearable
                    :label="t('sessions.deviceCategory')"
                    @update:model-value="setFilter('category', $event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.max_power.min"
                    :rules="[validateMinValue(filtersSelected.max_power.min, filtersSelected.max_power.max)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.MaxkWhMin')"
                    @update:model-value="setFilter('max_power', { min: $event, max: filtersSelected.max_power.max })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col class="py-0" md="3" sm="6" cols="12">
                <v-text-field
                    :model-value="filtersSelected.max_power.max"
                    :rules="[validateMaxValue(filtersSelected.max_power.max, filtersSelected.max_power.min)]"
                    density="compact"
                    type="number"
                    class="hide-arrows"
                    variant="outlined"
                    clearable
                    :min="0"
                    :label="t('sessions.MaxkWhMax')"
                    @update:model-value="setFilter('max_power', { max: $event, min: filtersSelected.max_power.min })"
                    @keypress="isNumber($event)"
                />
            </v-col>
            <v-col v-if="!isDriverDetail && store.state.account.drivers_count > 1" class="py-0" md="3" sm="6" cols="12">
                <v-autocomplete
                    v-value="filtersSelected.drivers"
                    :items="filters.drivers ? filters.drivers : []"
                    density="compact"
                    item-title="name"
                    chips
                    item-value="key"
                    variant="outlined"
                    multiple
                    clearable
                    :label="t('sessions.Drivers')"
                    @update:model-value="setFilter('driver_id', $event)"
                />
            </v-col>
            <v-col v-if="showClear" class="py-0" md="3" sm="6" cols="12">
                <Btn text color="primary" @click="clearFilters()">
                    <v-icon class="clear-icon pr-1"> mdi-delete </v-icon>{{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
