<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'

// components
import InfoMessage from '@/components/InfoMessage.vue'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const store = useStore()

const locale = computed(() => {
    return store.state.account.locale
})
</script>

<template>
    <section class="section bank-transfer-wrapper">
        <header class="header">
            <h2 class="header__title">
                {{ t('managePayment.PaymentSetToBankTransfer') }}
            </h2>
        </header>
        <main class="main mt-5">
            <InfoMessage class="mt-6">
                {{ t(`managePayment.bankTransferInfo${locale.toUpperCase()}`) }}<br>
            </InfoMessage>
        </main>
    </section>
</template>