import { createApp } from 'vue'

import AppWrapper from './AppWrapper.vue'
import store from './store'
import router from './router'
import {i18n} from './modules/i18n'
import vuetify from './modules/vuetify'
import userCentrics from './modules/userCentrics'
import VueCookies from 'vue3-cookies'

createApp(AppWrapper)
    .use(VueCookies, {
        domain: import.meta.env.VITE_APP_COOKIES_DOMAIN,
        expireTimes: `${import.meta.env.VITE_APP_COOKIES_DEFAULT_EXPIRATION_TIME}h` || '24h',
    })
    .use(store)
    .use(router)
    .use(vuetify)
    .use(i18n)
    .use(userCentrics)
    .mount('#app')
